import { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import ArrowLeft from '../../icons/arrow-left';
import Loader from '../loader';
import Styled from '../styled';
import Text from '../text';
import {
  Back,
  BreadcrumbContainer,
  BreadcrumbLink,
  Content,
  Description,
  Header,
  LeftCol,
  Main,
  PageLoaderContainer,
  RightCol,
  Title,
  TitleCard,
  TitleDetail,
  TitleImg,
} from './index.styles';
import useMediaQuery from '../../../utils/hooks/use-media-query';
import SessionLogo from '../../icons/session-logo';
import ChevronLeft from '../../icons/chevron-left';
import { useQuery } from 'react-query';
import { getCustomerSettings } from '../../../api/settings';

type Props = {
  title: string;
  description?: string;
  rightContent?: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  hasBackLink?: boolean;
  backLabel?: string;
  showDetails?: boolean;
  titleDetailText?: string;
  subtitleDetailText?: string;
  onBackClick?: () => void | undefined;
  showBackButton?: boolean;
};

const Page = ({
  title,
  description,
  rightContent = null,
  footer,
  children,
  hasBackLink = false,
  backLabel,
  showDetails,
  titleDetailText = '',
  subtitleDetailText = '',
  onBackClick,
  showBackButton = false,
}: Props) => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const history = useHistory();
  const options = {
    suspense: false,
    refetchOnMount: false,
    useErrorBoundary: true,
  };

  const { isLoading, data: d } = useQuery([getCustomerSettings], getCustomerSettings, options);

  const handleBackButton = () => {
    history.goBack();
  };
  return (
    <Main>
      <Header>
        {hasBackLink && (
          <Back onClick={onBackClick}>
            <Styled marginRight='1rem'>
              <ArrowLeft />
            </Styled>
            {backLabel}
          </Back>
        )}

        {showBackButton && (
          <BreadcrumbContainer onClick={handleBackButton}>
            <ChevronLeft fill='#f2f2f2' />
            <span>Dashboard</span>
          </BreadcrumbContainer>
        )}

        <Title>
          {!showDetails && <Text variant={isMobile ? 'large' : 'extraLarge3'}>{title}</Text>}

          {showDetails && (
            <TitleCard isMobile>
              {isLoading ? (
                <Loader size='small' />
              ) : (
                <TitleImg src={d?.scenarioImageUrl} isLoading={isLoading} isMobile={isMobile} isRound={true} />
              )}

              <TitleDetail>
                <Text variant={isMobile ? 'large' : 'extraLarge3'}>{title}</Text>
                <Text className='destak-text' variant={isMobile ? 'medium' : 'extraLarge2'}>
                  {titleDetailText}
                </Text>
                <Text className='detail-right' variant={isMobile ? 'xsMedium' : 'medium'}>
                  {subtitleDetailText}
                </Text>
              </TitleDetail>
            </TitleCard>
          )}
        </Title>

        <Description>
          <LeftCol>{description && !isMobile && <Text variant='medium'>{description}</Text>}</LeftCol>
          {rightContent && <RightCol>{rightContent}</RightCol>}
        </Description>
      </Header>
      <Content>{children}</Content>
      {footer}
    </Main>
  );
};

export const PageLoader = () => (
  <PageLoaderContainer>
    <Loader />
  </PageLoaderContainer>
);

export default Page;
