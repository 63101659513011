import { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
	PolarAngleAxis, PolarGrid, Radar,
	RadarChart,
	ResponsiveContainer
} from "recharts";
import { LegendStyle } from "./RadarC.styles";
import { AttributesProps, AttributesTypes, ChartPoint, LINES_COLORS } from "./index";
import { ChartWrapper } from "./score.styles";

const MULTIPLIER = 100;

const RadarC = ({ attributes }: AttributesProps) => {
	const intl = useIntl();
	const [fixedAttributes, setFixedAttributes] = useState(attributes);
	const svgRef = useRef<any>();

	const proceduralScores = attributes.filter(({ type }) => type.toUpperCase() === AttributesTypes.Procedural).map(({ name }) => name);

	useEffect(() => {
		setFixedAttributes(
			attributes.map((attribute) => ({
				...attribute,
				score: attribute.score * MULTIPLIER,
				passingScore: attribute.passingScore * MULTIPLIER,
			}))
		);
	}, [attributes]);

	return (
		<ChartWrapper flex={0.6}>
			<ResponsiveContainer width="100%" height="100%">
				<RadarChart
					cx="50%"
					cy="50%"
					outerRadius="80%"
					startAngle={45}
					endAngle={-315}
					ref={svgRef}
					data={fixedAttributes}
				>
					<PolarGrid stroke="#383838" gridType="circle" />
					<PolarAngleAxis
						dataKey="name"
						fontSize={10}
						tick={(d) => <Tick d={d} proceduralScores={proceduralScores} />}
					/>

					<Radar
						name={intl.formatMessage({ id: "session-detail:score-achieved", defaultMessage: "Score Achieved" })}
						dataKey="score"
						stroke={LINES_COLORS.scoreAchieved}
						fill={LINES_COLORS.scoreAchieved}
						fillOpacity={0.15}
						label={(point: ChartPoint) => (
							<ScoreText point={point} fill={LINES_COLORS.scoreAchieved} />
						)}
					/>

					<Radar
						name={intl.formatMessage({ id: "session-detail:expected-score", defaultMessage: "Expected Score" })}
						dataKey="passingScore"
						stroke={LINES_COLORS.expectedScore}
						fillOpacity={0}
						label={(point: ChartPoint) => (
							<ScoreText point={point} fill={LINES_COLORS.expectedScore} />
						)}
					/>
				</RadarChart>
			</ResponsiveContainer>
			<Legend />
		</ChartWrapper>
	);
};

const ScoreText = ({ point, fill }: { point: ChartPoint; fill: string }) => (
	<text
		fill={fill}
		x={point.x}
		y={point.y}
		textAnchor="middle"
		fontSize="0.5rem"
	>
		{point.value.toFixed(1)}
	</text>
);

const Tick = ({ d, proceduralScores }: { d: any, proceduralScores: string[] }) => {
	const { payload: { coordinate, value } } = d;
	const RADIUS = 5;
	const CHAR_WIDTH = 10;
	const angleIntervals = [
		{ min: -89, max: 89, transform: { x: RADIUS * 2, y: 0 } }, // Right
		{ min: -269, max: -91, transform: { x: -(value.length * CHAR_WIDTH + RADIUS), y: 0 } }, // Left
		{ min: -271, max: -269, transform: { x: -((value.length * CHAR_WIDTH) / 2), y: -RADIUS } }, // Top
		{ min: -91, max: -89, transform: { x: -((value.length * CHAR_WIDTH) / 2), y: RADIUS } }, // Bottom
	];

	const interval = angleIntervals.filter(({ min, max }) => coordinate > min && coordinate < max).shift();
	const point = { x: d.x + interval?.transform.x, y: d.y + interval?.transform.y };

	return (
		<g transform={`translate(${point.x} ${point.y})`}>
			<circle
				r={RADIUS}
				fill={proceduralScores.includes(value) ? LINES_COLORS.procedural : LINES_COLORS.physiological}
			/>
			<text
				fill={"#fff"}
				fontSize="1rem"
				x={15}
				y={5}
			>{value}</text>
		</g>
	);
}

const Legend = () => (
	<LegendStyle.Wrapper>
		<LegendStyle.Item>
			<LegendStyle.Icon type="line" color={LINES_COLORS.scoreAchieved} />
			<FormattedMessage id="session-detail:score-achieved" defaultMessage="Score Achieved" />
		</LegendStyle.Item>
		<LegendStyle.Item>
			<LegendStyle.Icon type="line" color={LINES_COLORS.expectedScore} />
			<FormattedMessage id="session-detail:expected-score" defaultMessage="Expected Score" />
		</LegendStyle.Item>

		<LegendStyle.Item>
			<LegendStyle.Icon type="circle" color={LINES_COLORS.procedural} />
			<FormattedMessage id="session-detail:procedural" defaultMessage="Procedural" />
		</LegendStyle.Item>
		<LegendStyle.Item>
			<LegendStyle.Icon type="circle" color={LINES_COLORS.physiological} />
			<FormattedMessage id="session-detail:physiological" defaultMessage="Physiological" />
		</LegendStyle.Item>
	</LegendStyle.Wrapper>
);

export default RadarC;
