import {
  DashboardIcon,
  LockIcon,
  SupportIcon,
  UsersIcon,
} from "../../components/icons";
import { useFeatureToggles } from "../../context/feature-toggles";
import { useUser } from "../../context/user";
import { Features } from "../../enums/features";
import { Pages } from "../../enums/pages";
import { Roles } from "../../enums/user";

const getNavigationItems = () => {
  const { state: user } = useUser();
  const { isFeatureActive } = useFeatureToggles();

  return [
    {
      icon: DashboardIcon,
      label: "Dashboard",
      path: Pages.Dashboard,
      enabled: Roles.Instructor !== user.role,
    },
    {
      icon: DashboardIcon,
      label: "Dashboard",
      path: Pages.DashboardAllTrainees,
      notAllowedTo: [Roles.Trainee],
      enabled: Roles.Trainee !== user.role,
    },
    {
      icon: LockIcon,
      label: "Authentication",
      path: Pages.Authentication,
      enabled: isFeatureActive(Features.AuthenticationPage),
    },
    {
      icon: UsersIcon,
      label: "Trainees",
      path: isFeatureActive(Features.PendingInvites)
        ? Pages.TraineesActive
        : Pages.Trainees,
      enabled: Roles.Trainee !== user.role,
    },
    {
      icon: SupportIcon,
      label: "Support",
      path: Pages.Support,
      enabled: true,
    },
  ].filter(({ enabled }) => enabled);
};

export default getNavigationItems;
